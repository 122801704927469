import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import {useState} from 'react';
import Button from '@mui/material/Button';
const steps = [
  
  'Contact Nostalgia',
  'Order  Confirmed',
  'Food Is Cooked',
  'Pickup'
];
const useStyles = makeStyles(() => ({
    root: {
        paddingTop: "30px",
        paddingBottom: "30px",
      "& .MuiStepIcon-active": { color: "#FFB81C" },
      "& .MuiStepIcon-completed": { color: "#FFB81C" },
      "& .Mui-disabled .MuiStepIcon-root": { color: "#FFB81C" }
    },
    
  }))
export default function HorizontalLabelPositionBelowStepper() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0)
    const stepper  = (step) =>{
        setActiveStep(step)
    }
  return (
      <>
       <br />
      
      <Typography variant="h1">
    
      Nostalgia Kitchen
     </Typography>
     <div style={{margin: "auto",
  width: "50%",
  
  padding: "10px"}}>
   {/* <Button 
          sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          variant="contained" size="large" style={{  backgroundColor:"#FF3008", color:"white", borderRadius:"20px"}}>
          <a 
        href="https://order.online/business/nostalgia-kitchen-11102441/en-US" target="_blank"
        style={{color:"white", textDecoration:"none"}}
        > 
              Order Now
              </a>
            </Button> */}
   </div>

      <br />
    
      <div style={{width:"80%", margin: "auto",
         width: "100%", padding:"10px"}}>
      <Typography variant="h5">
        An authentic Goan restaurant, available for takeout and catering. 
     </Typography>
      </div>
      <br />
     
     <Typography variant="h3">
         How It Works?
     </Typography>

      
    <Box sx={{ width:{ xs:"100%", md:"100%",}, backgroundColor:"white", padding:"10px" }} >
         
      <Stepper  className={classes.root} alternativeLabel style={{width:'100%'}} activeStep={4} >
        {steps.map((label) => (
        
          <Step key={label} >
            <StepLabel>
                <Typography >
                {label}
                </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
     
    
    </Box>
    </>
  );
}