import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Hero2 from '../components/Hero2';
import HowItWorks from '../components/HowItWorks';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';
import { Person } from "schema-dts";
import OrderChoris from "../components/OrderChoris"
import Header2 from "../components/Common/Header"
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  color: theme.palette.text.transparent,
 boxShadow: "none"
}));

const resturantData = {
  "@context": "https://schema.org",
  "@type": "Restaurant",
  "address": {
    "@type": "PostalAddress",
    "addressLocality": "Scarborough",
    "addressRegion": "ON",
    "postalCode": "M1L 4C2",
    "streetAddress": "773 Warden Ave. Unit 1A"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.5",
    "reviewCount": "8"
  },
  "name": "Nostalgia Kichen",
  "openingHours": [
    "Mo-Sa 11:00-18:00",
    "Mo-Th 11:00-18:30",
    "Fr-Sa 11:00-18:00"
  ],
  "priceRange": "$$",
  "servesCuisine": [
    "Goan Cuisine",
    "Snacks"
  ],
  "telephone": "(416) 792-7060",
  "url": "https://nostalgiakitchen.ca",
  "hasMenu": "https://nostalgiakitchen.ca/menu",
  "areaServed":["Toronto", "Scarborough", "Mississauga", "Pickering", "Milton", "Brampton"],
  "brand":"Nostalgia Kitchen"

}
const HomePage = () => {
  return  (
    <>
   <script type="application/ld+json">
        {JSON.stringify(resturantData)}
      </script>
    <Helmet >
  <title>Nostalgia Kitchen - Taste of Goa</title>
  <link rel="notImportant" href="https://nostalgiakitchen.ca/" />
  <meta name="description"   content="Nostalgia Kitchen & Foods is an Authentic Goan resturant in Toronto, Our menu includes delicious goan fish curry, Xacuti, Xec Xec, Sorpatel and much more " />
  <link rel="canonical" href="https://nostalgiakitchen.ca/" />
  <meta property="og:title" content="Nostalgia Kitchen - Taste of Goa"/>
  <meta property="og:type" content="website"/>
  <meta property="og:url" content="https://nostalgiakitchen.ca/"/>
  
</Helmet>   
  <Box sx={{ width: '100%', backgroundColor:"#F1F3E6" }}> 
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} style={{backgroundColor:"#FFB81C"}}>
                  <Item>
                    {/* <Header2 /> */}
                    <Header />
                 
                  </Item>
                 
            </Grid>
          <Grid item xs={12}>
                  <Item>
                    {/* <OrderChoris /> */}
                  </Item>
            </Grid>
            <Grid item xs={12} sx={{display:{xs:"none", lg:"inline"}}} >
                  <Item>
                    <Hero2 />
                  </Item>
            </Grid>
            <Grid item xs={12} sx={{display:{xs:"inline", lg:"none"}}} >
                  <Item><Hero /></Item>
            </Grid>
            <Grid container
              spacing={0}
              direction="column" 
              >
                  <Grid>
                    <Item><HowItWorks /></Item>
                  </Grid>

            </Grid>
            <Grid container
              spacing={0}
              direction="column"
              item
              xs={12}
              alignItems="center" 
              justifyContent="center"
              >
              <Item>  </Item>
            </Grid>          
    </Grid> 
          <Menu />
          <Footer />
  </Box>
  </>
  )
};

export default HomePage;
