import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Header from '../components/Header';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';
import { useLocation } from "react-router-dom";
import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  color: theme.palette.text.transparent,
 boxShadow: "none"
}));
const HomePage = () => {
  const location = useLocation(); 
  const [sizex, setSizeX] = useState("24oz");
  const [sizePrice, setSizePrice] = useState(80)
  const [additionItemPrice, setadditionItemPrice] = useState(5);
  const [additionItem, setadditionItem] = useState(false)
  const [total, setTotal] = useState({total: 0, subtotal:0, tax: 0});
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [nameError, setNameError] = useState("")
  const [emailError, setEmailError] = useState("")
  const [phoneError, setPhoneError] = useState("")
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [unit_number, setunit_number] = useState("");
  const [postal_code, setpostal_code] = useState("");
  const [city, setCity] = useState("");
  const [addresserror, setAddresserror] = useState(false);
  const [unit_numbererror, setunit_numbererror] = useState(false);
  const [postal_codeerror, setpostal_codeerror] = useState(false);
  const [cityerror, setCityerror] = useState(false);
  const [pickup, setPickup] = useState(true);
  const [dishes, setDishes] = useState([]);
  const [weekly_menu_meals, set_weekly_menu_meals] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [meals, setMeals] = useState([]);
  const [turn_off_remove, set_turn_off_remove] = useState(false);
  const [turn_off_add, set_turn_off_add] = useState(false);
  const [not_successfull_substitution, set_not_successfull_substitution] = useState(false)





  const calculateTotal = (item) => {
    setSizeX(item);
    if(item === "24oz"){
        let tempTotal = {subtotal: 80, tax: (80*.13).toFixed(2), total: (80*.13 + 80).toFixed(2) }
        setTotal(tempTotal)
    }
    if(item === "16oz"){
        let tempTotal = {subtotal: 70, tax: (70*.13).toFixed(2), total: (70*.13 + 70 ).toFixed(2)}
        setTotal(tempTotal)
    }   
  }

  useEffect(()=>{

    calculateTotal("16oz")
  },[])

  useEffect(()=>{
    // fetch("http://localhost:4000/get_weekly_Menu")

    fetch("https://admin.billfern.ca/get_weekly_Menu")
    .then((response)=>response.json())
    .then((data)=>{
      set_weekly_menu_meals(data)
      if(data && data.data && data.data.length === 0){
        setShowMenu(false)
        
      } else {
   let obj = data.data
      let temp_meals = [
        {
          name: obj.name1,
          quantity: 1, 
          substituted: false,
          substituted_for: ""
        },
          {
          name: obj.name2,
          quantity: 1,
          substituted: false,
           substituted_for: ""

        },
          {
          name: obj.name3,
          quantity: 1,
          substituted: false,
           substituted_for: ""

        },
          {
          name: obj.name4,
          quantity: 1,
          substituted: false,
           substituted_for: ""
        },
          {
          name: obj.name5,
          quantity: 1,
          substituted: false,
           substituted_for: ""
        },
          {
          name: obj.name6,
          quantity: 1,
          substituted: false,
           substituted_for: ""

        },

      ]
        setMeals(temp_meals)
      }
   
      setadditionItemPrice(parseInt(data.data.additional_item_price))
      

  })
    .catch(e=>console.log(e))
  },[])



  const submitOrder = (e) =>{
  
    if(name === ""){
      setNameError("Please Enter your name")
      return
    }
    if(email === ""){
      setEmailError("Please Enter your email")
      return
    }
    if(phone === ""){
      setPhoneError("Please Enter your Phone number")
      return
    }
    if(pickup === false){
        if(address === ""){
            setAddresserror("Please Enter your address")
            return
          }
          if(postal_code === ""){
            setpostal_codeerror("Please Enter your Postal Code")
            return
          }
          if(city === ""){
            setCityerror("Please Enter your City")
            return
          }
    }


    if(name && phone && email ){
      setLoading(true)
      let data = {}
      if(pickup){
         data = {
            name: name,
            phone: phone,
            email: email,
            tax:  (parseFloat(total.tax) +( additionItem ? (additionItemPrice *0.13) : 0) 
            -  (pickup === true ? (5*.13): 0 )
            // + (pickup === false ? 6*.13 : 0)
            ).toFixed(2),
            orderSize: sizex,
            additionItem: additionItem ? "yes" : "false",
            pickup: pickup ? "in-store" : "delivery",
            total: (parseFloat(total.total )   + ( additionItem ? (additionItemPrice *0.13) + additionItemPrice  : 0)
            -  (pickup === true ? (5*.13 + 5): 0 )
            ).toFixed(2),
            meals: meals
    
          }
      } else {
         data = {
            name: name,
            phone: phone,
            email: email,
            tax:  (parseFloat(total.tax) +( additionItem ? (additionItemPrice *0.13) : 0) 
            -  (pickup === true ? (5*.13): 0 )
            // +  (pickup === false ? 6*.13 : 0)
            ).toFixed(2),
            orderSize: sizex,
            additionItem: additionItem ? "yes" : "false",
            pickup: pickup ? "in-store" : "delivery",
            address: address,
            unit_number: unit_number,
            postal_code: postal_code,
            city: city,
            total: (parseFloat(total.total )  + ( additionItem ? (additionItemPrice *0.13) + additionItemPrice  : 0)
            // + (pickup === false ? 6*.13 + 6 : 0 )
            -  (pickup === true ? (5*.13 + 5): 0 )
            
            ).toFixed(2),
            meals: meals
    
          }
      }
     


      var canContinue = true
    // fetch("http://localhost:4000/sendweeklymenu",{

      fetch("https://admin.billfern.ca/sendweeklymenu", {
            method: 'POST',
            headers: {
             'Content-Type': 'application/json',
             "Access-Control-Origin": "*",
             'Access-Control-Allow-Origin': "*"
            },
            body: JSON.stringify(data)
        })
      .then(response=>response.json())
      .then((data)=>{
        setLoading(false)
        setSuccess(true)
    

      
        
      }).catch(e=> {setLoading(false); alert(e)})
    // if(canContinue){

    //   fetch("https://admin.billfern.ca/sendOrder",{
    //     method: 'POST',
    //     headers: {
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify(data)
      
    //   } ).then(response=>response.json()).then((data)=>
      
    //   {
    //     if(data.data === "success"){
    //           setLoading(false)
    //         setName("") 
    //         setEmail ("") 
    //         setPhone("") 
    //         setSuccess(true)
      
          
      
            
    //   }}
      
    //   ).catch((e)=>{console.log(e);setSuccess(false);  setLoading(false)})
    // }
    }
  }

  const increase_count = (i) =>{  
    if(meals[i].quantity <2){
     
      let temp_meals = meals
      temp_meals[i].quantity = temp_meals[i].quantity + 1
      setMeals([...temp_meals])
    
    
    } else {
      return 
    }

  }

  const substitute_meal = (mealName) =>{
    set_not_successfull_substitution(false)
    let already_substitution_made_index = meals.findIndex((el)=>(el.substituted_for === weekly_menu_meals.data.substituted_item))
    let temp_meals = meals
    if(!mealName){
        setMeals(meals.map((el)=>{
          return {...el, "substituted_for": ""}
        }))
    } else {
     
      if(already_substitution_made_index > -1){
       
        temp_meals[already_substitution_made_index].substituted_for = ""
        setMeals([...temp_meals])
        
      }
      let temp_meals_index = meals.findIndex((el)=> el.name === mealName)
      if(temp_meals_index > -1){
        temp_meals[temp_meals_index].substituted_for = weekly_menu_meals.data.substituted_item
        setMeals([...temp_meals])
        if( temp_meals[temp_meals_index].quantity <1){
          set_not_successfull_substitution(true)
        }
  
      }
    }
   

  }
  useEffect(()=>{
    let total_items = meals.reduce((a,b)=> a + b.quantity, 0)

    if(total_items > 6){
      set_turn_off_add(true);
      set_turn_off_remove(false);
    } else if(total_items === 6) {
      set_turn_off_remove(false);
        set_turn_off_add(true);

    } else {
      
      set_turn_off_add(false);
      set_turn_off_remove(true);
    }

  },[meals])

  const decrease_count = (i) =>{  
    if(meals[i].quantity){
      let temp_meals = meals
      temp_meals[i].quantity = temp_meals[i].quantity - 1
      setMeals([...temp_meals])
    
    } else {
      return 
    }

  }



  return  (
    <>
    <Helmet >
<title>Nostalgia Kitchen - Weekly Menu</title>
<link rel="notImportant" href="https://www.nostalgiakitchen.ca/weeklymenu" />
<meta name="description"   content="View Our Delicious Goan Menu Weekly" />
<link rel="canonical" href="https://www.nostalgiakitchen.ca/weeklymenu" />
<meta property="og:title" content="Nostalgia Kitchen - Taste of Goa"/>
<meta property="og:type" content="website"/>
<meta property="og:url" content="https://www.nostalgiakitchen.ca/weeklymenu"/>

</Helmet> 
   
  <Box sx={{ width: '100%'}}> 
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
    <Grid item xs={12}>
          <Item><Header /></Item>
        </Grid>
         <Grid item xs={12} style={{margin:"5px"}}>
                <div style={{display: !showMenu ? "none" : ""}}>
                    <div>
                    <div style={{display:"flex", justifyContent:"center"}}> 
                        <h1>Nostalgia's Weekly Menu now available online</h1> <br />
                    </div>
                        <div style={{display:"flex", justifyContent:"center"}}>
                            <p>
                                Order six delicious dishes, either 16oz or enjoy more of your favourites and get 24oz containers.
                                The food is delivered cool and needs to be warmed up.<br />
                                 <b>Please place order by {!weekly_menu_meals ? <CircularProgress style={{color:"#8B0000"}}/> : weekly_menu_meals.data.place_order_by} </b> <br />
                                Delivery Date <b>{!weekly_menu_meals ? <CircularProgress style={{color:"#8B0000"}}/> : weekly_menu_meals.data.delivery_date}</b>
                            </p>
                        </div>
                        <div style={{display:"flex", justifyContent:"center"}}>
                            <b>The six dishes for this week are:</b>
                         </div>
                         <div style={{display:"flex", justifyContent:"center", width:"100%"}}>
                            <div style={{display:"flex", justifyContent:"center"}}>
                                {meals.reduce((a,b)=> a + b.quantity, 0) < 6 && <b style={{color:"#8B0000"}}>** Please add another Dish
                                <br /> **Minimum of 6 items are required. You only have {meals.reduce((a,b)=> a + b.quantity, 0)} items.
                                </b>}
                                {meals.reduce((a,b)=> a + b.quantity, 0) < 6 && <b style={{color:"#8B0000"}}></b>}
                              
                              </div>
                         </div>
                          
                        <div style={{display:"flex", justifyContent:"center"}}>
                        {!weekly_menu_meals && <CircularProgress style={{color:"#8B0000"}}/> }
                         {/* <ol>
                            <li>  
                              {!weekly_menu_meals ? <CircularProgress style={{color:"#8B0000"}}/> : weekly_menu_meals.data.name1}
                            </li>
                            <li>  
                              {!weekly_menu_meals ? <CircularProgress style={{color:"#8B0000"}}/> : weekly_menu_meals.data.name2}
                            </li>
                            <li>  
                              {!weekly_menu_meals ? <CircularProgress style={{color:"#8B0000"}}/> : weekly_menu_meals.data.name3}
                            </li>
                            <li>  
                              {!weekly_menu_meals ? <CircularProgress style={{color:"#8B0000"}}/> : weekly_menu_meals.data.name4}
                            </li>
                            <li>  
                              {!weekly_menu_meals ? <CircularProgress style={{color:"#8B0000"}}/> : weekly_menu_meals.data.name5}
                            </li>
                            <li>  
                              {!weekly_menu_meals ? <CircularProgress style={{color:"#8B0000"}}/> : weekly_menu_meals.data.name6}
                            </li>                  
                         </ol> */}
                       {weekly_menu_meals && showMenu &&meals && meals.length > 0 && 
                        <table>
                          {meals.map((el, i)=>(
                            <tr >
                              <td style={{wordBreak:"break-word", width:"70%", color: el.substituted_for? "darkred": ""}}>{el.substituted_for ? el.substituted_for :el.name}</td>
                              <td style={{wordBreak:"break-word", width:"10%"}} >
                                {i <= 3 && !turn_off_remove && <button style={{ backgroundColor:"white", margin:"5px"}} onClick={()=>decrease_count(i)}>-</button>} 
                              {i <= 3 && !turn_off_add && <button style={{ backgroundColor:"white", margin:"5px"}}  onClick={()=>increase_count(i)}>+</button>}</td>
                              {/* <td disabled={turn_off_remove} onClick={()=>decrease_count(i)}style={{wordBreak:"break-word", width:"10%"}}> </td> */}
                              <td  style={{padding:"5px", width:"10%"}}>{el.quantity}</td>
                             
                            </tr>
                          ))}
                        
                        
                         </table>}
                        </div>
                        <div style={{display:"flex", justifyContent:"center"}}>
                        <input type="radio" 
                                id="16oz"
                                name="size"
                                value="16oz" 
                                    onChange={(event) => calculateTotal(event.target.value)} inline
                                    checked={sizex === "16oz"} 
                                    />
									
                        <label htmlFor="16oz">16oz</label>
                        
					        <input type="radio" 
                            id="24oz"

                            name="size"
                            value="24oz" 
                                onChange={(event) => calculateTotal(event.target.value)} inline
                                 checked={sizex === "24oz"} 
                                 />
									
                            <label htmlFor="24oz">24oz</label>
                           
                            
                        </div>
                        <div style={{display: weekly_menu_meals ? "flex" : "none", justifyContent:'center'}}>
                           <label>
                                <input checked={additionItem} onChange={(e)=> setadditionItem(!additionItem)}  type="checkbox"/>
                                    Add {!weekly_menu_meals ? <CircularProgress style={{color:"#8B0000"}}/> : weekly_menu_meals.data.additional_item} to order ${!weekly_menu_meals ? <CircularProgress style={{color:"#8B0000"}}/> :weekly_menu_meals.data.additional_item_price}
                            </label>
                        </div>
                        <div style={{display: weekly_menu_meals ? "flex" : "none", justifyContent:'center', flexDirection:"row", gap:"5px"}}>
                            <label for="cars">Subsitute <b>One</b> Item for <b>{weekly_menu_meals &&  weekly_menu_meals.data.substituted_item}</b></label>
                            <div style={{justifyContent:"center", display:"flex"}}>
                                <select name="cars" id="cars" onChange={(e)=>substitute_meal(e.target.value)}>
                                <option value="">Select Item to be replaced</option>
                                <option value="">Remove Subsitution</option>
                                  {meals.map((el, i)=>(i < 4 ?  <option value={el.name}>{el.name}</option> : ""))}
                                </select>
                                <br />
                            </div>
                          
                   
                            

                        </div>
                        <div style={{display:"flex", justifyContent:"center"}}>
                                {not_successfull_substitution && <b style={{color:"darkred"}}> Hi there,This is a bit awkward, the subsitution you have made has 0 quantity.</b>}
                            </div>
                      
                       
                    <div>

 
                    </div>
                    {/* 12-September-2023 -BF Deploy once server is available */}
                     <Stack spacing={2}>
                            <div style={{ display:"flex", justifyContent:"center"}}>

                            <div style={{maxWidth:"50%", display:"flex", flexDirection:"column", gap:"5px"}}>
                                <TextField  size="small" id="standard-basic" label="Name" variant="outlined" error={nameError}  value={name} onChange={(e)=>{setName(e.target.value); setNameError("")}}/>     
                                <span style={{color:"red"}}>{nameError}</span>
                                <TextField size="small" id="standard-basic" label="Email" variant="outlined" error={emailError} value={email} onChange={(e)=>{setEmail(e.target.value); setEmailError("")}}/>   
                                <span style={{color:"red"}}>{emailError}</span>
                                <TextField size="small" id="standard-basic" label="Phone Number" error={phoneError} value={phone} variant="outlined" onChange={(e)=>{setPhone(e.target.value); setPhoneError("")}}/>    
                                <span style={{color:"red"}}>{phoneError}</span>
                            </div>

                            </div>
                          
                            <Box sx={{ minWidth: 200 }}>
                            <div style={{display:"flex", justifyContent:"center", margin:"5px"}}>
                                            <input type="radio" 
                                                    id="pickup"
                                                    name="typeofdelvery"
                                                    value="16oz" 
                                                        onChange={(event) => setPickup(true)} inline
                                                        checked={pickup === true} 
                                                        />
                                                        
                                            <label htmlFor="pickup">Pick up in store</label>
                                            
                                                <input type="radio" 
                                                id="delivery"

                                                name="typeofdelvery"
                                                value="delivery" 
                                                    onChange={(event) => setPickup(false)} inline
                                                    checked={pickup === false} 
                                                    />
                                                        
                                                <label htmlFor="delivery">Delivery</label>
                                                
                                            </div>
                                            <div style={{display:"flex", justifyContent:"center",  margin:"5px"}}>
                                                {pickup === false && 
                                                <div style={{display:"flex", justifyContent:"center", flexDirection:"column", gap:"5px"}}>
                                                <TextField size="small" id="standard-basic" label="Address*" error={addresserror} value={address} variant="outlined" onChange={(e)=>{setAddress(e.target.value); setAddresserror("")}}/>    
                                                    <span style={{color:"darkred"}}>{addresserror}</span>
                                               
                                                <TextField size="small" id="standard-basic" label="Unit number" error={unit_numbererror} value={unit_number} variant="outlined" onChange={(e)=>{setunit_number(e.target.value); setunit_numbererror("")}}/>    
                                                
                                                <TextField size="small" id="standard-basic" label="Postal Code*" error={postal_codeerror} value={postal_code} variant="outlined" onChange={(e)=>{setpostal_code(e.target.value); setpostal_codeerror("")}}/>    
                                            <span style={{color:"darkred"}}>{postal_codeerror}</span>
                                                
                                                <TextField size="small" id="standard-basic" label="City*" error={cityerror} value={city} variant="outlined" onChange={(e)=>{setCity(e.target.value); setCityerror("")}}/>    
                                                <span style={{color:"darkred"}}>{cityerror}</span>
                                    
                                    </div>
                                                }
                                            </div>
                    
                        </Box>
                    </Stack> 
      <div style={{display:"flex", justifyContent:"center"}}>
                            <div style={{borderTop:"1px black dashed", borderBottom:"1px black dashed", padding:"5px"}}>
                            
                                Sub-Total: ${total.subtotal -  (pickup === true ? 5: 0 ) +( additionItem ? additionItemPrice : 0) } <br />
                                {pickup === false && <s>"Delivery Charge: $6"</s>}
                                {pickup === false && <br />}
                                tax: ${(parseFloat(total.tax) +( additionItem ? (additionItemPrice *0.13) : 0)
                                -  (pickup === true ? (5*.13): 0 ) 
                                // + (pickup === false ? 6*.13 : 0)
                                ).toFixed(2)} <br />
                               <b>Total: ${(parseFloat(total.total )  + ( additionItem ? (additionItemPrice *0.13) + additionItemPrice  : 0)
                              -  (pickup === true ? (5*.13 + 5): 0 )
                              //  + (pickup === false ? 6*.13 + 6 : 0 )
                               ).toFixed(2) }</b> <br />
                                   {/*<Button style={{
                                     backgroundColor:"#8B0000",
                                     color:"white",
                                     marginRight:"10px",
                                     marginBottom:"10px",
                                     border: '1px black solid',
                                     borderRadius:"0px"
                                }}
                                onClick={ ()=>w
                                // onClick={ ()=>submitOrder()}indow.open('tel:647-863-9249')}

                                >Call 647-863-9249 to place order</Button> */}
                            </div>
                         </div> 
                <div style={{display:'flex', justifyContent:'center'}}>
                      {/* 12-September-2023 -BF Deploy once server is available */}
          {loading ? <CircularProgress style={{color:"#8B0000"}}/> : 
                <Button style={{
                                     backgroundColor: meals.reduce((a,b)=> a + b.quantity, 0) < 6 ? "gray" : "#8B0000",
                                     color: "white",
                                     marginRight:"10px",
                                     marginBottom:"10px",
                                     border: '1px black solid',
                                     borderRadius:"0px",
                                     display: success ? "none" : ""
                                    }}
                                    disabled={success || (meals.reduce((a,b)=> a + b.quantity, 0) < 6)}
                                // onClick={ ()=>window.open('tel:647-863-9249')}
                                onClick={ ()=>submitOrder()}

                                >Submit Order</Button>} 
             </div>
             <div style={{display:"flex", justifyContent:"center"}}>
                        {success && "Your Order was placed successfully. Please wait for a confirmation call. "}
             </div>

                    </div>

                </div>
        </Grid>
        <div style={{display: showMenu ? "none" :'flex', justifyContent:"center", textAlign:"center", width:"100%", padding:"20px"}}>
                                    <b>Unfortunately, this Menu is no longer available, please wait for next week's menu or contact nostalgia to check for availability.</b>
        </div>
       
    <Footer />
    </Grid>   
  </Box>
  </>
  )
};

export default HomePage;
