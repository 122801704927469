import {Carousel, Image} from 'react-bootstrap'
import React from 'react';
import {useState} from 'react'
import OpenNow from '../assets/images/dazzle.png';
import Menu from '../assets/images/5menu.png';
import Biryani from '../assets/images/Biryani.png';
import tastebuds from '../assets/images/tastebuds/tastebuds -992.png';
import favSnack from '../assets/images/favSnack/favSnack -992.png';
import BillysBowl from '../assets/images/ricebowls/billybowl 992.png';



function Hero2() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} 
    
    
  >
      <Carousel.Item  interval={1500}>
        <Image
          src={tastebuds}
          alt="Indulge your taste buds with nostalgia"
          style={{
          
            
          }}
          responsive="true"
        />
       
      </Carousel.Item>
      <Carousel.Item  interval={1500}>
        <Image
          src={favSnack}
          alt="amazing goan snack"
          style={{
          
            
          }}
          responsive="true"
        />
       
      </Carousel.Item>
      <Carousel.Item  interval={1500}>
        <Image
          src={BillysBowl}
          alt="Billy's Bowl"
          style={{
          
            
          }}
          responsive="true"
        />
       
      </Carousel.Item>
      
      {/*Correction <Carousel.Item interval={1500}>
        <Image
          className="d-block w-100"
          src={Menu}
          alt="Second slide"
          responsive="true"
          style={{
            maxWidth:"100%",
            height:"50%",
            
            
          }}
           
        />

       
      </Carousel.Item> */}
      
     
    </Carousel>
  );
}

export default Hero2;



