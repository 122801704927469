import {useState, useContext} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import HeadLogo from '../assets/images/nosk1.png'
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CartContext from '../Context/CartContext';
import ShowCart from './ShowCart'
import { Link} from "react-router-dom";
import Stack from '@mui/material/Stack';
import HomeIcon from '@mui/icons-material/Home';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import CollectionsIcon from '@mui/icons-material/Collections';
import { SiCodechef } from "react-icons/si";
import SideMenu from './SideMenu';
import {BsCalendarEvent} from "react-icons/bs"
const pages = ['HOME','Weekly Menu', 'MENU', 'GALLERY', 'ABOUT US'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const {cart, setCart} = useContext(CartContext)
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" color="transparent" >
      <Container maxWidth="xl">
         
        <Toolbar disableGutters>
          <Box
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
             <Link to="/" >

            <img src={HeadLogo} style={{maxWidth:"300px", paddingTop:"10px"}}/>
             </Link>
             
          </Box>
 
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
         
              <SideMenu />
       
           
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                  fontSize:"300px",
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center" color="secondary" >{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
             <Link to="/" >

             <img src={HeadLogo} style={{maxWidth:"200px", paddingTop:"10px"}}/>
             </Link>
          </Typography>
          

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' , paddingLeft:"50px"  } }}>
          <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'black', display: 'block'}}
                size="large"

              >
            <Link to="/" style={{textDecoration:"none", fontSize:"20px", color:"black", 
            alignItems:"left", display:"inline-block", verticalAlign:"5px"
            }}>
            <HomeIcon fontSize="medium" style={{color:"#FFB81C", display:"inline-block", verticalAlign:"-5px"}}/>  
              HOME
            </Link>
            </Button>
            <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'black', display: 'block'}}
                size="large"

              >
            <Link to="/menu" style={{textDecoration:"none", fontSize:"20px", color:"black"
          , alignItems:"left", display:"inline-block", verticalAlign:"5px"}}>
                   <RestaurantMenuIcon  fontSize="medium" style={{color:"#FFB81C", display:"inline-block", verticalAlign:"-5px"}}/>   MENU
            </Link>
            </Button>
            <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'black', display: 'block'}}
                size="large"

              >
           <Link to="/weeklymenu" style={{textDecoration:"none", fontSize:"20px", color:"black"
          , alignItems:"left", display:"inline-block", verticalAlign:"5px"}}>
                   <RestaurantMenuIcon  fontSize="medium" style={{color:"#FFB81C", display:"inline-block", verticalAlign:"-5px"}}/>WEEKLY MENU
            </Link>
            </Button>
            <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'black', display: 'block'}}
                size="large"

              >
           <Link to="/events" style={{textDecoration:"none", fontSize:"20px", color:"black"
          , alignItems:"left", display:"inline-block", verticalAlign:"5px"}}>
                   <BsCalendarEvent size={30}  fontSize="medium" style={{color:"#FFB81C", display:"inline-block", verticalAlign:"-5px"}}/>Events
            </Link>
            </Button>
            
            
           
            <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'black', display: 'block'}}
                size="large"

              >
                
            <Link to="/about" style={{textDecoration:"none", fontSize:"20px", color:"black",
                 alignItems:"left", display:"inline-block", verticalAlign:"15px"
               
              }}>
           
              <SiCodechef  style={{color:"#FFB81C",
              fontSize:"40px" ,
               display:"inline-block", verticalAlign:"-5px"}}/>

            About
          
            </Link>
            </Button>

       
        
            

            
          
          </Box>
         
          {/* DoorDash <Button 
          sx={{ display: { xs: 'none', md: 'flex'  } }}
          variant="contained" size="large" style={{  backgroundColor:"#FF3008", color:"white", borderRadius:"20px"}}>
          <a 
        href="https://order.online/business/nostalgia-kitchen-11102441/en-US" target="_blank"
        style={{color:"white", textDecoration:"none"}}
        > 
              Order Now
              </a>
            </Button> */}
    

             <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex',  paddingLeft:"50px"  } }}>
         



             <Typography variant="h4" component="div" gutterBottom
                        style={{fontSize:"20px", paddingLeft:"50px"}}
                        >   
                          <b>Nostalgia Kitchen</b> <br />
                            Tel: (416) 792-7060 <br />
                           Text: (647) 863-9249
                            <br />773 Warden Avenue Unit 1A, Scarborough, ON
                        </Typography> 
                        
             </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', lg:'flex' } }}> 
                <Stack direction="row" spacing={2}>
                    
        


        </Stack>
        </Box>
       
        
        
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              {/* <Badge badgeContent={cart.length}    color="error">
                    
             </Badge> */}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
            
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
