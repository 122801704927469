import {Carousel, Image} from 'react-bootstrap'
import React from 'react';
import {useState} from 'react'
import OpenNow from '../assets/images/dazzel1.png';
import Menu from '../assets/images/menu.png';
import Biryani from '../assets/images/Biryani.png';
import tastebuds from '../assets/images/tastebuds/tastebuds-576px.png';
import favSnack from '../assets/images/favSnack/favSnack 576px.png';
import Button from '@mui/material/Button';
import BillysBowl from '../assets/images/ricebowls/billybowl 576.png';

function Hero2() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <>     
    <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item  interval={1500}>
        <Image
          className="d-block w-100 fit"
          src={tastebuds}
          alt="Indulge your taste budds with nostalgia"
          style={{
            maxWidth:"100%",
            height:"50%",            
          }}
          responsive="true"
        />
       
      </Carousel.Item>
      <Carousel.Item  interval={1500}>
        <Image
          className="d-block w-100 fit"
          src={favSnack}
          alt="Favourite snack"
          style={{
            maxWidth:"100%",
            height:"50%",            
          }}
          responsive="true"
        />
       
      </Carousel.Item>
      <Carousel.Item  interval={1500}>
        <Image
          className="d-block w-100 fit"
          src={BillysBowl}
          alt="Billy's bowl"
          style={{
            maxWidth:"100%",
            height:"50%",            
          }}
          responsive="true"
        />
       
      </Carousel.Item>
      {/* <Carousel.Item interval={1500}>
        <Image
          className="d-block w-100"
          src={Menu}
          alt="Second slide"
          responsive="true"
          style={{
            maxWidth:"100%",
            height:"50%",
            
            
          }}
           
        />
      <Carousel.Caption style={{alignItems:"center", left:"30%",  position:"absolute", zIndex:"1", bottom:"10%"}}>
      
      </Carousel.Caption>
       
      </Carousel.Item> */}
      
     
    </Carousel>
   

    </>


  );
}

export default Hero2;



