import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { AiOutlineFacebook, AiFillTwitterSquare } from "react-icons/ai";
import { FaTiktok, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FiPhoneCall } from "react-icons/fi";
import { BsChatSquareText } from "react-icons/bs";
import Typography from '@mui/material/Typography';
import ChefIcon from '../assets/images/Chef.png'
import FoodBankIcon from '@mui/icons-material/FoodBank';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: "none",
  backgroundColor:"black"

}));

export default function FullWidthGrid() {
  return (
      <>
      
      <br />
    <Box sx={{ flexGrow: 1, backgroundColor:"black" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Item> 
              <Typography variant="h2" 
                 style={{color:"white", paddingLeft:"1px", fontSize: "calc(1em + 4.0vw)"}}>
              Nostalgia Kitchen
              </Typography>
              
              </Item>
        </Grid>
        <Grid item xs={12} md={4}>
          <Item> 
              <Typography variant="h2"  style={{color:"white", fontSize: "calc(1em + 1.2vw)"}}>
                 <b>  Connect </b>
              
              </Typography>
        <a 
        href="https://facebook.com/gtagoanfood" target="_blank"
        style={{color:"white", textDecoration:"none", fontSize: "calc(1em + 0.2vw)"}}
        >
         <Typography variant="h2"  style={{color:"white", fontSize: "calc(1em + 0.5vw)",
          paddingTop:"5px"}}>
            <AiOutlineFacebook /> 

        Facebook
         </Typography>
        
    
        </a>
        <Typography variant="h2"  style={{color:"white",
         fontSize: "calc(1em + 0.5vw)"
         ,paddingTop:"10px"
    }}>
        <FiPhoneCall  /> 416-792-7060
        </Typography>
        
        <Typography variant="h2"  style={{color:"white", fontSize: "calc(1em + 0.5vw)"
      ,paddingTop:"10px"
    }}><BsChatSquareText  /> 647-863-9249
        </Typography>
        
        </Item>
        </Grid>
        <Grid item xs={12} md={3}>
          <Item>
          <Typography variant="h2"  style={{color:"white", fontSize: "calc(1em + 1.2vw)"}}>Nostalgia Kitchen Hour's <br />
     
          </Typography>
                  
        <Typography variant="h2"  style={{color:"white", fontSize: "calc(1em + 0.5vw)"
          ,paddingTop:"10px"
        }}>
      Monday    11am- 6pm <br />
      Tuesday    11am- 6pm <br />
      Wednesday    11am- 6pm <br />
      Thursday    11am- 6pm <br />
      Friday    11am- 6pm <br />
      Saturday    11am- 6pm <br />
      Sunday    11am- 6pm <br />
      <br />
     

        </Typography>
    
     </Item>
                     
       
        </Grid>
      </Grid>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{backgroundColor:"#FFFAE7"}}
      >
      <Typography variant="h3"  style={{color:"black", fontSize: "calc(1em + 0.5vw)"
          ,paddingTop:"10px", 
        }}>

        Website Designed By Billy F 
        </Typography>

        </Box>
    </Box>
    </>
  );
}