import React from 'react'
import Header from '../components/Header';
import AboutUs from '../components/Aboutus';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';

const ChefPage = () => {
  return (
    <>
   <Helmet >
 <title>About Us</title>
 <link rel="notImportant" href="https://nostalgiakitchen.ca/about" />
 <meta name="description"   content="About Nostalgia Kitchen " />
 <link rel="canonical" href="https://nostalgiakitchen.ca/about" />
 <meta property="og:title" content="Nostalgia Kitchen - About us"/>
 <meta property="og:type" content="website"/>
 <meta property="og:url" content="https://nostalgiakitchen.ca/about"/>
 
</Helmet>
    <div>
        <Header/>
    <AboutUs />
    <Footer />
    </div>
   
    </>
  )
}

export default ChefPage